import { Injectable } from "@angular/core";
import { OtpPinComponent } from "@app/shared/components/otp-pin/otp-pin.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { combineLatest, lastValueFrom, map, skip, startWith, take } from "rxjs";
import { AuthService } from "../abstract-services/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class OtpPinValidatorService {
  otpDialogRef: DynamicDialogRef | undefined;

  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: AuthService
  ) {}

  async showValidator(): Promise<boolean> {

    const verifyToken = await this.authService.createVerifyToken()
    this.otpDialogRef = this.dialogService.open(OtpPinComponent, {
      header: 'Validación 2FA',
      height: '280px',
      modal: true,
      closable: true,
      dismissableMask: true,
      styleClass: 'oy-modal',
      data: {
        verifyToken: verifyToken.verifyTwoFactorToken
      }
    })

    const observableHandler$ = combineLatest({
      success: this.authService.onVerifyTokenSuccess$().pipe(startWith(false), take(2)),
      closed: this.otpDialogRef.onClose.pipe(startWith(false), take(2))
    }).pipe(
      skip(1),
      map(({success, closed}) => {
        if(success) {
          this.otpDialogRef.close()
          return true
        };
        if(closed) return false;
        return false;
      }),
      take(1)
    )

    return lastValueFrom(observableHandler$)
  }
}